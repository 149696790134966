:root {
  --background-color: #151515;
  --text-color: #dcdcdc;
  --hover-color: #66B2FF;
  --item-bg-color: #1C1C1C;
  --border-color: #8C9A9A;
  --title-color: #0080C4;
  --background-hover: #373737;
  --button4-hover: #3399FF;
}

:root[data-theme='light'] {
  --background-color: #ffffff;
  --text-color: #000000;
  --hover-color: #3399FF;
  --item-bg-color: #f9f9f9;
  --border-color: #cccccc;
  --title-color: #3399FF;
  --background: #ffffff;
  --background-hover: #8C9A9A;
  --button4-hover: #ffffff;
}

.itemCategory {
  margin-top: 2px;
  margin-left:60px;
  margin-bottom: 2px;
  margin-right: 910px;
}

.itemCategory:hover {
  cursor: pointer;
  color: #66B2FF; /* Change the text color on hover */
}

.itemCategory {
  transition: transform 0.3s;
}

.itemCategory:hover {
  transform: translateX(4px);
}

.item {
  margin-left: 100px;
  margin-bottom: 16px;
  margin-top: 4px;
  padding: 16px;
  background: var(--item-bg-color);
  border: 1px solid #8C9A9A
}

.itemTitle {
  font-size: 16px;
  margin-top: 1px;
  color: #8C9A9A;
  margin-left: 76px;

}

.itemTitle:hover {
  cursor: pointer;
  color: #66B2FF; /* Change the text color on hover blue #0080C4 gray #8C9A9A */
}

.typeFilter {
  margin-left: 32px;
}

.typeFilterSelected {
  color: #0080C4
}

.inputFilter {
  margin-top: 0px;
  margin-left:60px;
  margin-bottom:20px;
  padding: 0px;
  color: var(--text-color);
}

.App {
  display: "block";
  padding-top: 0px;
  margin: 0 auto;
  max-width: 1200px;
}

.App-items {
  padding-top: 0;
}

header h1:before {
  content: "";
  display: inline-block;
  width: 38px; /* Set your desired width */
  height: 38px; /* Set your desired height */
  margin-right: 8px;
  vertical-align: text-top;
  background: url("icon.png") no-repeat center center;
  background-size: contain; /* Ensure the image fits within the specified dimensions */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace
}

.container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto
}

section {
  display: block;
  margin: 0 0 20px 0
}

h1,h2,h3,h4,h5,h6 {
  margin: 0 0 20px;
}

li {
  line-height: 1.4
}

header {
  background: var(--background-color);
  width: 100%;
  
  padding-top: 20px; /* Set padding for the top */
  padding-right: 0;  /* Set padding for the right */
  padding-bottom: 0px; /* Set padding for the bottom */
  padding-left: 0;  /* Set padding for the left */
}

header h1 {
  font-size: 36px;
  line-height: 1.5;
  margin: 0 0 0 -40px;
  font-weight: bold;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
  color: #0080C4;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1),0 0 5px rgba(181,232,83,0.1),0 0 10px rgba(181,232,83,0.1);
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased
}

@media (max-width: 1000px) {
  header h1 {
      margin-left:0;
  }
  .itemCategory {
    margin-right:0;
  }
}

header h2 {
  font-size: 18px;
  font-weight: 300;
  color: var(--text-color);
}

section img {
  max-width: 100%
}

h1,h2,h5,h6 {
  font-weight: normal;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
  color: #0080C4;
  letter-spacing: -0.03em;

}

h3 {
  font-weight: normal;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
  color: #9b5923;
  letter-spacing: -0.03em;

}

h4 {
  font-weight: bold;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
  color: #9b2923;
  font-style: italic;
  margin-bottom: 0px;
}

h5 {
  font-size: 22px;
  padding-left: 30px;
  font-weight: 300;
  color: var(--text-color);
}

h6 {
  font-size: 12px;
  padding-left: 71px;
  color: var(--text-color);
  margin-bottom: 0px;
  margin-top: 0px;
  font-style: italic;
  word-spacing: -2px;
  cursor: default;
}

blockquote {
  color: #aaa;
  padding-left: 10px;
  border-left: 1px dotted #8C9A9A
}

code.highlighter-rouge {
  background: #112033;
  border: 1px solid rgba(255,255,255,0.15);
  padding: 0px 3px;
  margin: 0px -3px;
  color: #888;
  border-radius: 2px
}

table {
  width: 100%;
  margin: 0 0 20px 0
}

a {
  color: #66B2FF;
  text-decoration: none
}

a:hover {background-color: #373737;}

.copy-button {
  cursor: pointer;
  /* Add additional styling as needed */
}

/* Add this to your APP.css or relevant stylesheet */
.copy-button {
  cursor: pointer;
  /* Set initial button color to white */
  background-color: #dcdcdc;
  color: #0080C4;
  border: 1px solid #0080C4; /* Add a border for better visibility */
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  transition-duration: 0.3s;
}

/* Change button color to green on hover */
.copy-button:hover {
  background-color: #0080C4;
  color: #dcdcdc; /* Change text color on hover */
}
.button4 {
  background: var(--background-color);
  color: var(--text-color);
  font-size: 14px;
  font-weight: bold;
  border: 0px solid #373737;
  padding: 2px 10px; /* Adjust padding as needed */
  border-radius: 8px;
  transition: 0.5s;
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Display as inline-block to take padding and border */
}
.button4:hover {
  background-color: var(--background-hover);
  color: var(--button4-hover);

}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Apply animation to the Changelog link */
.button4.animation {
  animation: pulse 2s infinite;
}

.notice {
  color: red;
  font-weight: bold;
  margin-top: 10px;
  padding-left: 30px; /* Default margin-left */
  margin-bottom: 20px; /* Corrected the missing 'px' */
}

.icon-container {
  vertical-align: text-top;

}

